"use client";

import "./globals.scss";
import "antd/dist/reset.css";
import { Inter_Tight } from "next/font/google";
import { ConfigProvider, Empty } from "@/lib/antd";
import { ThemeProvider, useTheme } from "@/contexts/themeContext/themeContext";
import StyledComponentsRegistry from "@/lib/antd-registry";
import EnhancedCookieConsent from "@/components/consent/consent";
import { AntdRegistry } from "@ant-design/nextjs-registry";
import MobileOnboarding from "@/components/mobile_view/onboarding_page";

const inter = Inter_Tight({
  subsets: ["latin"],
  variable: "--font-inter-tight",
});

// Custom hook for detecting mobile view

// Mobile view component
const MobileView = () => {
  return (
    <AntdRegistry>
      <ThemeProvider>
        <ThemedApp>
          <MobileOnboarding />
        </ThemedApp>
      </ThemeProvider>
    </AntdRegistry>
  );
};

// Desktop view component wrapped with necessary providers
const DesktopView = ({ children }: { children: React.ReactNode }) => {
  return (
    <AntdRegistry>
      <ThemeProvider>
        <ThemedApp>{children}</ThemedApp>
      </ThemeProvider>
    </AntdRegistry>
  );
};

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <html lang="en">
      <body className={inter.className}>
        <DesktopView>{children}</DesktopView>
      </body>
    </html>
  );
}

const ThemedApp = ({ children }: { children: React.ReactNode }) => {
  const { themeConfig, themeConfigColors, mode } = useTheme();

  return (
    <body
      style={{ backgroundColor: themeConfigColors.background }}
      className={inter.className}
    >
      <EnhancedCookieConsent />

      <StyledComponentsRegistry>
        <ConfigProvider theme={themeConfig}>{children}</ConfigProvider>
      </StyledComponentsRegistry>
    </body>
  );
};
