"use client";

import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo,
  useEffect,
} from "react";
import {
  darkThemeConfigColors,
  lightThemeConfigColors,
  TypeThemeConfigColors,
} from "@/theme/theme-config";
import { ThemeConfig } from "antd";
interface ThemeContextType {
  themeConfig: ThemeConfig;
  themeConfigColors: TypeThemeConfigColors;
  mode: Mode;
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
}

type Mode = "dark" | "light";

const ThemeContext = createContext<ThemeContextType>({
  themeConfig: {},
  themeConfigColors: lightThemeConfigColors,
  mode: "light",
  setMode: () => {},
});

const getInitialTheme = (): Mode => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("theme") === "dark" ? "dark" : "light";
  }
  return "light";
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [mode, setMode] = useState<Mode>(getInitialTheme());
  const [mounted, setMounted] = useState(false);
  
  useEffect(() => {
    setMounted(true);
    document.body.classList.toggle("dark-mode", mode === "dark");
  }, []);

  useEffect(() => {
    if (mounted) {
      document.body.classList.toggle("dark-mode", mode === "dark");
    }
  }, [mode, mounted]);

  const dynamicThemeConfig: ThemeConfig = useMemo(() => {
    const colors =
      mode === "dark" ? darkThemeConfigColors : lightThemeConfigColors;

    return {
      token: {
        fontSize: 16,
        colorPrimary: colors.primary,
        colorBorder: colors.borderColor,
        colorError: colors.error,
        colorText: colors.primaryText,
        colorTextDisabled: colors.textDisabled,
        colorTextSecondary: colors.secondaryText,
        colorSuccess: colors.success,
        fontFamily: 'var(--font-inter-tight), sans-serif',
        controlHeight: 41,
        colorBgContainer: colors.cardBackgroundColor,
        colorBorderSecondary: colors.secondaryBorderColor,
        colorBgElevated: colors.secondaryBackground,
        colorTextDescription: colors.secondaryText,
        colorFillSecondary: colors.background,
        colorTextQuaternary: colors.quaternaryText,
        colorErrorBg: colors.bgError,
        colorErrorBorder: colors.borderError,
        colorInfo: colors.colorInfo,
        colorWarning: colors.colorWarning,
        colorWarningBg: colors.colorWarningBg,
        colorWarningBorder: colors.colorWarningBorder,
        colorInfoBg: colors.colorInfoBg,
        colorInfoBorder: colors.colorInfoBorder,
        colorSuccessBg: colors.colorSuccessBg,
        colorSuccessBorder: colors.colorSuccessBorder,
      },
      components: {
        Tabs: {
          itemActiveColor: colors.primaryText,
          verticalItemMargin: "0",
          cardPadding: "8px 16px",
        },
        Form: {
          verticalLabelPadding: "0 0 4px",
          controlHeight: 41,
        },
        Input: {
          colorBgContainer: colors.cardBackgroundColor,
          colorTextPlaceholder: colors.secondaryText,
          colorTextDisabled: colors.textDisabled,
          // colorText: '#FCFCFC',
          // colorTextPlaceholder: '#808080',
          // colorBorder: '#333333',
          // hoverBorderColor:themeConfigColors.background,
          // activeBorderColor:themeConfigColors.background,
          // activeShadow:"0 0 3px "+themeConfigColors.background,
          borderRadius: 8,
          colorError: colors.error,
          colorErrorBorderHover: colors.error,
        },
        Card: {
          colorTextHeading: colors.primaryText,
          colorBgContainer: colors.background,
          colorBorder: colors.borderColor,
        },
        Typography: {
          colorText: colors.primaryText,
          colorTextSecondary: colors.secondaryText,
        },
        Layout: { siderBg: colors.siderBg, bodyBg: colors.background },
        Checkbox: {
          colorBorder: colors.borderColor,
          colorBgContainer: colors.transparent,
          lineWidth: 2,
          paddingXS: 15,
          controlInteractiveSize: 20,
        },
        Select: {
          optionSelectedColor: colors.primaryText,
          optionSelectedBg: colors.primaryLight,
          colorTextSecondary: colors.secondary,
        },
        Button: {
          // colorBgContainer: themeConfigColors.primary,
          borderRadius: 8,
          colorBorder: colors.primary,
          colorText: colors.primary,
          colorPrimaryText: "white",
          // colorBgContainerDisabled: "grey",
          // colorTextDisabled: "white",
          // boxShadow:"4px 4px 8px 0px #AA81DA",
          padding: 12,
          paddingInline: "24px",
          paddingBlock: "12px",
          controlHeight: 48,
          primaryShadow:
            mode == "dark"
              ? "4px 4px 8px 0px #5B3A89"
              : "4px 4px 8px 0px #AA81DA",
          colorPrimaryBg:
            mode == "dark"
              ? "linear-gradient(0deg, #2C0276 0%, #4E1C8A 100%)"
              : "linear-gradient(0deg, #5502B4 0%, #8041C7 100%)",
          defaultBorderColor: mode == "dark" ? "#333333" : "#ffffff",
          defaultHoverBg:
            mode == "dark"
              ? "linear-gradient(154.92deg, #444444 15.82%, #555555 83.93%)"
              : "linear-gradient(154.92deg, #F5F5F5 15.82%, #EDEAF0 83.93%)",
          defaultHoverBorderColor: mode == "dark" ? "#444444" : "#ffffff",
          defaultShadow:
            mode == "dark"
              ? "0 2px 4px rgba(0, 0, 0, 0.2)"
              : "0 2px 4px rgba(0, 0, 0, 0.6)",
          defaultBg:
            mode == "dark"
              ? "linear-gradient(154.92deg, #444444 15.82%, #555555 83.93%)"
              : "linear-gradient(154.92deg, #F5F5F5 15.82%, #EDEAF0 83.93%)",
        },
        Segmented: {
          itemSelectedBg: colors.menuItemSelectedBG,
          trackBg: colors.background,
          borderRadiusSM: 8,
          borderRadiusXS: 6,
          trackPadding: 2,
          itemSelectedColor: colors.primaryText,
        },
        Divider: {
          // colorTextHeading: "#595959",
          fontSize: 16,
          // colorSplit: "#595959",
          colorBorder: "#CECECE",
          colorBorderBg: "#CECECE",
          margin: 24,
        },
        Menu: {
          itemBorderRadius: 8,
          padding: 16,
          darkItemColor: colors.secondaryText,
          darkItemHoverColor: colors.secondaryText,
          darkItemSelectedColor: colors.primaryText,
          darkItemHoverBg: colors.menuItemHoverBG,
          darkItemSelectedBg: colors.menuItemSelectedBG,
        },
        Collapse: {
          contentBg: colors.cardBackgroundColor,
          headerBg: colors.cardBackgroundColor,
          contentPadding: "8px",
        },
        Table: {
          headerBg: colors.cardBackgroundColor,
          borderColor: colors.borderColor,
          rowHoverBg: colors.cardBackgroundColor,
          footerBg: colors.cardBackgroundColor,
        },
        Modal: {
          contentBg: colors.secondaryBackground,
          footerBg: colors.secondaryBackground,
          headerBg: colors.secondaryBackground,
        },
        Alert: {
          colorPrimary: "black",
        },
      },
    };
  }, [mode]);

  const themeConfigColors =
    mode === "dark" ? darkThemeConfigColors : lightThemeConfigColors;

  if (!mounted) {
    return null;
  }
  return (
    <ThemeContext.Provider
      value={{
        themeConfig: dynamicThemeConfig,
        themeConfigColors,
        mode,
        setMode,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
